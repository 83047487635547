<template>
    <div class="wrapper">
<!--           购物车页面-->
      <headBack>
        <template v-slot:title>
          <div class='title'>
             购物车
          </div>
        </template>
      </headBack>
      <div class='header_cont'></div>
      <div class="cart_box">
        <div class="no_box" v-if="noMessage">
          <img src="https://static.yihu365.cn/img/h5Img/shop/no_order.png" alt="">
          <div>您的购物车没有商品，快去添加商品吧</div>
        </div>
        <van-checkbox-group v-model="checked" ref="checkboxGroup">
        <div class="item_b" v-for="(item,index) in infos.goodsVOS" :key="item.goodsId">
          <div class="item">
              <div class="check" @click="clickCheck"><van-checkbox :name="item.goodsId" checked-color="#00c291" icon-size="19"/></div>
              <div class="image_wrap">
                <img :src="item.iconUrl" alt="">
              </div>
              <div class="item_r">
                <div class="name">{{item.goodsName}}</div>
                <div class="p_box">
                  <div class="price">¥<span class="num">{{ item.unitPrice }}</span></div>
                  <div class="num_wrap">
<!--                    <span class="minus"></span>-->
<!--                    <div><input type="tel" class="num" value="12"></div>-->
<!--                    <span class="plus"></span>-->
                    <van-stepper v-model="item.goodsNum" @change="updataCart(item,1)"/>
                  </div>
                </div>
              </div>
          </div>
          <div class="schu" @click="delCart(item)"><span>删除</span></div>
        </div>
        </van-checkbox-group>
      </div>
      <div class="bottom_fixed">
        <div class="kf">
              <van-checkbox  @click="checkedAll" v-model="checkAll" checked-color="#00c291" icon-size="19" >全选</van-checkbox>
        </div>
        <div class="gow">
             <div class="all_p">总计：<span>¥</span><span class="num">{{total}} </span></div>
             <div class="n_txt">共{{allNums}}件</div>
        </div>
        <button class="b_btn" @click="toOrderPage">提交订单</button>
      </div>
    </div>
</template>

<script>
import {onMounted, ref, computed,watch} from "vue"
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex'
import shopApi from '@axios/shop';
import { Dialog } from 'vant'

import {Toast} from 'vant';
import moment from 'moment';

export default {
  name: "cart",
  setup(){
    let router = useRouter()
    const store = useStore()
    const route = useRoute()
    const infos = ref('')
   const noMessage = ref(false)
    const getInfos = () => {
      const params = {
         userId : store.state.uid
      }
      shopApi.getCartInfos(params).then((res)=> {
         infos.value = res.data;
         console.log(infos.value)
        if(res.data.goodsVOS.length == 0){
          noMessage.value = true;
        }else{
          noMessage.value = false;
        }
        checkedAll()
      })
    }
    getInfos();

    //  全选
    const checked = ref([]);
    const checkAll = ref(false);
    const checkboxGroup = ref(null);


    const nums = ref('')
    const clickCheck = () => {
      console.log(checked.value)
      if(checked.value.length == infos.value.goodsVOS.length){
        checkAll.value =true;
      }else {
        checkAll.value =false;
      }
    }
    const checkedAll = () => {//点击全选
      if (checkAll.value) {
        checked.value = infos.value.goodsVOS.map((item,index) => item.goodsId)
      } else {
        checked.value = []
      }
    }
    //数据监听
    watch(checked,(newValue,oldValue)=> {
      console.log(newValue)
    })


    //计算总价

    const total = computed( () =>{
      console.log('infos',infos.value)
         let str=0;
         if(infos.value.goodsVOS){
           infos.value.goodsVOS.forEach((item)=> {
             if(checked.value.includes(item.goodsId)){
               str+= Number(item.unitPrice) * Number(item.goodsNum);
             }

           })
         }
       // console.log(str)
      return str.toFixed(2);
    })

    const allNums = computed( () =>{
      let strs=0;
      if(infos.value.goodsVOS){
        infos.value.goodsVOS.forEach((item)=> {
          if(checked.value.includes(item.goodsId)){
            strs+= item.goodsNum;
          }
        });
      }

      return strs;
    })
    //增加
    const add = () => {
      console.log("+")
      console.log(infos.value)
      console.log( checked.value)
    }
    const reduce = () => {
    }
    //更新商品
    const updataCart = (item,types)=> {
      console.log(item)
      const params = {
        userId : store.state.uid,
        synchronization:[{
          goodsId : item.goodsId,
          goodsNum : item.goodsNum,
          type : types,
          unitPrice : item.price,
          goodsName: item.goodsName,
          iconUrl : item.iconUrl,
        }]
      }
      console.log(params)
      shopApi. setSynchronization(params).then((res)=> {
        console.log('t',res)
        infos.value = res.data;
        if(res.data.goodsVOS.length == 0){
          noMessage.value = true;
        }else{
          noMessage.value = false;
        }
      })
    }

    //删除购物车
    const delCart = (item) => {
      Dialog.confirm({
        title: '',
        message: '确认删除该商品吗？',
      }).then(() => {
            updataCart(item,2)
      }).catch(() => {
            // on cancel
      });
    }

    const toOrderPage = () => {
       if(checked.value.length == 0){
         Toast("请勾选购物车商品")
         return;
       }
         let params = []
         infos.value.goodsVOS.forEach((item,index)=> {
           if(checked.value.includes(item.goodsId)){
             console.log(item)
             params.push(item)
           }
         })
         const obj = {
           'allNums':allNums.value,
           'total':total.value,
           'list':params
         }
         sessionStorage.setItem('orderData',JSON.stringify(obj));
         router.push({
           name : 'order',
           query:{
             type:'2'
           }
         })
    }



    return {
      infos,
      checked,
      checkAll,
      getInfos,
      clickCheck,
      add,
      reduce,
      checkboxGroup,
      checkedAll,
      total,
      allNums,
      delCart,
      updataCart,
      nums,
      toOrderPage,
      noMessage

    }
  }
}
</script>

<style scoped lang="scss">
    ::v-deep(.van-stepper__input){
      background: #FFFFFF;
    }
    .wrapper {
      -webkit-font-smoothing:antialiased;
        min-height: 100vh;
       background: #f2f4f5;
       //background: #ffff;
        .cart_box{
          box-sizing: border-box;
          padding:30px 30px 100px 30px;
          .item_b{
            box-sizing: border-box;
            margin-bottom: 30px;
            //background: #f9f9f9;
            background: #fff;
            border-radius: 16px;
            padding:22px 24px 12px 16px;
            .schu{
              margin-right: 20px;
              font-size: 28px;
              text-align: right;
              line-height: 50px;
              margin-top: 14px;
            }
          }
          .item{
            box-sizing: border-box;
            display: flex;
            .check{
               align-self: center;
              margin: 0 6px;
            }
            .image_wrap{
              img{
                width: 180px;
                height: 180px;
                border-radius:8px;
                margin: 0 18px;
              }
            }
            .item_r{
              color:#262626;
               font-size: 30px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              box-sizing: border-box;
              flex-grow: 2;
              padding:6px 0;
               .name{
                 text-overflow: ellipsis;
                 font-weight: bolder;
                 display: -webkit-box;
                 -webkit-line-clamp: 2;
                 -webkit-box-orient: vertical;

                 display: -moz-box;
                 -moz-line-clamp: 2;
                 -moz-box-orient: vertical;

                 overflow-wrap: break-word;
                 word-break: break-all;
                 white-space: normal;
                 overflow: hidden;
               }
              .p_box{
                display: flex;
                justify-content: space-between;
                .price{
                  color:#FF4A26;
                  //font-weight: bolder;
                  span{
                    font-size: 30px;
                  }
                  .num{
                    font-size: 37px;
                  }
                }
                .num_wrap{
                  display: flex;

                  .minus{
                    display: block;
                    width: 30px;
                    height: 30px;
                    position: relative;
                  }
                  .minus:before{
                   background: url("https://static.yihu365.cn/img/h5Img/shop/icon_-.png") no-repeat 50%;
                    background-size:30px 30px;
                    content: "";
                    left: 0;
                    position: absolute;
                    top: 0;
                    width: 30px;
                    height: 30px;
                  }
                  .plus{
                    display: block;
                    width: 30px;
                    height: 30px;
                    position: relative;
                  }
                  .plus:before{
                    background: url("https://static.yihu365.cn/img/h5Img/shop/icon_jia.png") no-repeat 50%;
                    background-size:30px 30px;
                    content: "";
                    width: 30px;
                    height: 30px;
                    left: 0;
                    position: absolute;
                    top: 0;

                  }
                  input{
                    -webkit-appearance: none;
                    background-color: #f2f2f2;
                    border: none;
                    color: #262626;
                    font-display: swap;
                    font-size: 24px;
                    text-align: center;
                    width: 50px;
                  }
                }
              }
            }
          }
        }
    }
    .goods-card {
      margin: 0;
      background-color: white;
    }

    .delete-button {
      height: 100%;
    }

    .bottom_fixed{
      color:#262626;
      width: 100%;
      height: 120px;
      position: fixed;
      left:0;
      bottom:0;
      border-top:1px solid #eee;
      background: #fff;
      display: flex;
      font-size: 26px;
      align-items: center;
      box-sizing: border-box;
      padding-left:40px;

      .kf{
        width:150px;
        font-size: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

      }
      .gow{
        font-size: 28px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        .all_p{
           color:#FF4A26;
           .num{
             font-size: 37px;
           }
        }
        .n_txt{
          color:#666666;
        }
      }
      button{
        text-align: center;
        width: 240px;
        border:none;
        height:100px;
        font-size: 28px;
        color:#fff;
      }

      .b_btn{
        font-size: 32px;
        margin-right: 30px;
        border-radius: 40px;
        height:74px;
        //background: #00c291;
        background: linear-gradient(to bottom right, #25c011 0%, #00c291 100%);


      }
    }
    .no_box{
      color:#999999;
      padding-bottom: 20px;
      text-align: center;
      font-size: 28px;
      img{
        width: 300px;
      }
    }
</style>
